<template>
  <div
    class="center-title row-between"
    :class="{ border }"
    :style="{ marginBottom: bottom + 'px' ,paddingBottom:pb + 'px'}"
  >
    <div class="title">{{ title }}</div>
    <div class="right">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    // 页面标题
    title: {
      type: String,
      default: "",
    },
    // 标题间距
    pb: {
      type: [String, Number],
      default: 10,
    },
    // 标题间距
    bottom: {
      type: [String, Number],
      default: 20,
    },
    // 标题底部边框
    border: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
.center-title {
  line-height: 43px;
  &.border {
    border-bottom: 1px solid $-color-primary;
  }
  .title {
    font-size: 25px;
  }
}
</style>